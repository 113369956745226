import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section } from "../../components/Core";
import PostCard from "../../components/PostCard";

interface IRadioList {
  data: [
    {
      frontmatter: {
        title: string;
        previewImageAlt: string;
        previewImage: {
          childImageSharp: {
            fluid: {
              src: string;
            };
          };
        };
        slug: string;
      };
    }
  ];
}
const CaseList: React.FC<IRadioList> = ({ data }) => {
  const training = data;

  return (
    <>
      {/* <!-- Feature section --> */}
      <Section className="position-relative">
        <Container>
          <Row className="align-items-center justify-content-start">
            {training.map((radio) => (
              <Col lg="4" sm="6" className="mb-4 mb-lg-5">
                <PostCard
                  img={radio.frontmatter.previewImage.childImageSharp.fluid.src}
                  imgAlt={radio.frontmatter.previewImageAlt}
                  readMore="Start Training"
                  to={`/training/${radio.frontmatter.slug}`}
                  buttonText={radio.frontmatter.title}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default CaseList;
